import React from "react";

/**
 * Rendering this helps with listening to events and redirecting to appropriate overlay even if we don't show anything on the overlay.
 * @returns {JSX.Element}
 * @constructor
 */
function Overlay() {
  return <div></div>;
}

export function meta() {
  return {
    title: [null, "CS2 - Overlay"],
    description: [null, "CS2 overlay"],
  };
}

export default Overlay;
